import React from "react"
import { motion } from "framer-motion"
import FoldContainer from "../components/FoldContainer"
import Hero from "../components/Hero"
import HeroBackground from "../images/hero-background.jpg"
import MarginContainer from "../components/MarginContainer"
import SalimbayGif from "../images/salimbay.gif"
import styled from "styled-components"
import SectionHeader from "../components/SectionHeader"
import speakers from "../speakers.json"
import Speaker from "../components/Speaker"

const SalimbayContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`

const SalimbayImage = styled(motion.img)`
  ${({ theme }) => `
    width: 20rem;

    @media (min-width: ${theme.breakpoints[2]}){
      width: 35rem;
    }
  `}
`

const HeroContainer = styled.div`
  color: white;
  display: flex;
  padding-top: calc(3rem + 30px);
  width: inherit;
  height: calc(100% - 3rem - 30px);
  align-items: center;
  justify-content: center;
`

const HeroContentContainer = styled.div`
  ${({ theme }) => `
    display: block;
    width inherit;
    font-family: ${theme.fonts.proxima};
  `}
`

const HeroText = styled(motion.div)`
  ${({ theme }) => `
    color: inherit;
    font-family: inherit;
    font-size: ${theme.fontSize.rg};
    text-align: center;
    text-shadow: 2px 2px 5px #020202;
    max-width: 30rem;
    margin: auto;
  `}
`

const Italic = styled.span`
  font-style: italic;
`

const Bold = styled.span`
  font-weight: bold;
`

const Uppercase = styled.span`
  text-transform: uppercase;
`

const PresenterContainer = styled.div`
  margin-bottom: 2rem;
`

const DateContainer = styled.div`
  margin-top: 1rem;
`

const textVariant = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
}

const opacityVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const ProgramList = styled.ol`
  ${({ theme }) => `
    list-style-type: upper-roman;
    margin-left: 3rem;
    margin-top: 1rem;
    font-family: ${theme.fonts.proxima};

    li {
      padding-left: 10px;
    }
  `}
`

const SpeakerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const BrochureContainer = styled(FoldContainer)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.colors.darkBlue};
    color: white;
    font-size: ${theme.fontSize[1]};
    margin-top: ${theme.spaces[2]};
    font-family: ${theme.fonts.proxima};
    padding: 30px 15px;
  `}
`

const BrochureButton = styled.button`
  ${({ theme }) => `
    background-color: ${theme.colors.violet};
    color: white;
    display: inline-block;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.sm};
    padding: 5px 20px;
    outline: 0;
    text-decoration: none;
    border: 0;
    box-shadow: none;
    border-radius: 20px;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
    }
  `}
`

const preloadImages = [HeroBackground, SalimbayGif]

const downloadPdf = () => {
  const link = document.createElement("a")
  link.href = "/Salimbay Souvenir Program 2021.pdf"
  link.download = "Salimbay Souvenir Program 2021.pdf"
  link.dispatchEvent(new MouseEvent("click"))
}

const ProgramsPage = () => (
  <>
    <Hero preloadImages={preloadImages} backgroundImage={HeroBackground}>
      <MarginContainer>
        <HeroContainer>
          <HeroContentContainer>
            <PresenterContainer>
              <HeroText
                animate="visible"
                initial="hidden"
                variants={textVariant}
                transition={{ ease: "linear" }}
              >
                <Bold>University of the Philippines Manila</Bold>
              </HeroText>
              <HeroText
                animate="visible"
                initial="hidden"
                variants={textVariant}
                transition={{ delay: 0.2, ease: "linear" }}
              >
                <Italic>The Health Sciences Center</Italic>
              </HeroText>
              <HeroText
                animate="visible"
                initial="hidden"
                variants={textVariant}
                transition={{ delay: 0.3, ease: "linear" }}
              >
                <Bold>
                  <Uppercase>College of Medicine</Uppercase> and{" "}
                  <Uppercase>Philippine General Hospital</Uppercase>
                </Bold>
              </HeroText>
            </PresenterContainer>
            <SalimbayContainer>
              <SalimbayImage
                src={SalimbayGif}
                animate="visible"
                initial="hidden"
                transition={{ delay: 0.5, ease: "linear" }}
                variant={opacityVariant}
              />
            </SalimbayContainer>
            <HeroText
              animate="visible"
              initial="hidden"
              variants={textVariant}
              transition={{ delay: 1, ease: "linear" }}
            >
              The UP College of Medicine 112th Commencement Exercises and
              Philippine General Hospital Internship Program Closing Ceremonies
            </HeroText>
            <DateContainer>
              <HeroText
                animate="visible"
                initial="hidden"
                variants={textVariant}
                transition={{ delay: 1.2, ease: "linear" }}
              >
                <Bold>08 August 2021 | 12 NN</Bold>
              </HeroText>
              <HeroText
                animate="visible"
                initial="hidden"
                variants={textVariant}
                transition={{ delay: 1.2, ease: "linear" }}
              >
                Virtual Commencement Ceremony
              </HeroText>
            </DateContainer>
          </HeroContentContainer>
        </HeroContainer>
      </MarginContainer>
    </Hero>
    <FoldContainer>
      <MarginContainer>
        <SectionHeader>Program</SectionHeader>
        <ProgramList type="i">
          <li>Invocation and National Anthem</li>
          <li>
            Welcome Remarks by <Italic>Dean Charlotte Martinez Chiong</Italic>
          </li>
          <li>
            Opening Remarks by <Italic>Director Gerardo Dizon Legaspi</Italic>
          </li>
          <li>
            Petition for the conferment of the degree of Doctor of Medicine
            by&nbsp;<Italic>Dr. Ethan Angelo Suello Maslog</Italic>
          </li>
          <li>
            Presentation of the medical graduates to the UP Manila Chancellor
            by&nbsp;<Italic> Dean Charlotte Martinez Chiong</Italic>
          </li>
          <li>
            Presentation of the clinical interns to the UP Manila Chancellor
            by&nbsp;<Italic>Director Gerardo Dizon Legaspi</Italic>
          </li>
          <li>
            Conferment of the degree of Doctor of Medicine and declaration of
            completion of Internship by&nbsp;
            <Italic>Chancellor Carmencita David-Padilla</Italic>
          </li>
          <li>
            Petition for the conferment of the degree of MD-PhD by&nbsp;
            <Italic>Dr. Francis James Añonuevo Gordovez</Italic>
          </li>
          <li>
            Presentation of the graduates of the Diploma, Master’s and MD-PhD
            programs to the UP Manila Chancellor by&nbsp;
            <Italic>Dean Charlotte Martinez Chiong</Italic>
          </li>
          <li>
            Conferment of the MD-PhD degree by&nbsp;
            <Italic>Chancellor Carmencita David-Padilla</Italic>
          </li>
          <li>
            Introduction of the Commencement Speaker by&nbsp;
            <Italic>Chancellor Carmencita David-Padilla</Italic>
          </li>
          <li>
            Commencement Address by&nbsp;
            <Italic>Dr. Paul Gideon Dionela Lasco</Italic>
          </li>
          <li>
            Awarding of Diplomas and Certificates of Internship to the UPCM
            Graduates
          </li>
          <li>
            Awarding of Diplomas and Certificates of Internship to the UPCM
            Graduates with Latin Honors and the Top 10
          </li>
          <li>Awarding of Certificates of Internship to the MD-PhD students</li>
          <li>
            Awarding of Diplomas to the graduates of the Master’s programs
          </li>
          <li>Awarding of Diplomas and Special Awards to the MD-PhDs</li>
          <li>Presentation of Special Awards for Faculty and Graduates</li>
          <li>
            Message on behalf of Class 2021 by&nbsp;
            <Italic>Dr. Omid Javier Siahmard</Italic>
          </li>
          <li>
            Induction to the UP Medical Alumni Society (UPMAS) by&nbsp;
            <Italic>Dr. Lara Theresa Alentajan-Aleta</Italic>
          </li>
          <li>
            Induction to the PGH Association of Medical Alumni (PGH-AMA)
            by&nbsp;<Italic>Dr. Leopoldo M. Abad III</Italic>
          </li>
          <li>
            The Oath of Hippocrates by&nbsp;
            <Italic>Dean Charlotte Martinez Chiong</Italic>
          </li>
          <li>TRP song by UPCM Class 2021 and UP MedChoir</li>
          <li>PGH Hymn, Awit ng Kolehiyo, and UP Naming Mahal</li>
        </ProgramList>
      </MarginContainer>
    </FoldContainer>
    <BrochureContainer>
      Download Salimbay Souvenir Program
      <BrochureButton onClick={downloadPdf}>
        Click here to download
      </BrochureButton>
    </BrochureContainer>
    <FoldContainer>
      <MarginContainer>
        <SectionHeader>Speakers</SectionHeader>
        <SpeakerContainer>
          {speakers.map(speaker => (
            <Speaker {...speaker} key={speaker.name} />
          ))}
        </SpeakerContainer>
      </MarginContainer>
    </FoldContainer>
  </>
)

export default ProgramsPage
